<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <Events />
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Events from './form/Events.vue'

export default {
  components: {
    Events,
  },
}
</script>
